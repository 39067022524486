<template>
  <FullScreenDialog
    v-model="localDialog"
    title="Salda credito"
    without-padding
    @input="handleFullScreenDialogChange"
    :can-close="canCloseDialog"
  >
    <template v-slot:content>
      <template v-if="cashDeskOpened">
        <v-stepper v-model="currentStep" height="100%">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.name"
                :complete="currentStep > index + 1"
                :step="index + 1"
                :editable="currentStep > index + 1 && canChangeStep"
                :disabled="!canChangeStep"
                @click="handleStepClicked(index + 1)"
                edit-icon="mdi-check"
                v-ripple="false"
              >
                {{step.title}}
                <div class="text-caption">{{step.subtitle}}</div>
              </v-stepper-step>
              <v-divider :key="step.name + 'divider'" v-if="index != steps.length - 1"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(step, index) in steps">
              <v-stepper-content 
                :step="index + 1"
                :key="step.name + '_if'"
                v-if="step.name == 'invoiceOrReceipt'"
              >
                <div
                  style="height: 70vh; width: 100%;overflow-y:auto"
                  class="d-flex justify-center align-center flex-wrap"
                >
                <ResponsiveCardSelector
                  v-if="billServiceItemList.length > 1"
                  multiple
                  :items="billServiceItemList"
                  v-model="selectedServiceOrItem"
                  item-key="id"
                  card-max-height="180"
                  card-min-height="180"
                  card-max-width="180"
                  card-min-width="180"
                  justify-space-around
                  align-center
                  return-object
                  shaped
                  color="primary"
                >
                  <template v-slot:item="{ item }" >
                    <div v-if="!!item.service" class="pa-2 d-flex flex-column justify-center align-center">
                      <div class="mt-2 text-h6 text-center">{{ item.service.name }}</div>
                      <div class="mt-2 text-caption text-center">{{ (Number(item.price) * Number(item.quantity)).toFixed(2) }} €</div>
                    </div>
                    <div v-else-if="!!item.item " class="pa-2 d-flex flex-column justify-center align-center">
                      <div class="mt-2 text-h6">{{ item.item.description }}</div>
                      <div class="mt-2 text-caption text-center">{{ (Number(item.price) * Number(item.quantity)).toFixed(2) }} €</div>
                    </div>
                  </template>
                </ResponsiveCardSelector>

                <v-card
                  v-if="selectedServiceOrItem.length == 0"
                  color="primary"
                  class="text-h6 d-flex align-center justify-center ml-2"
                  height="200"
                  width="200"
                  @click="handleReceiptClick()"
                  shaped
                >
                  <div>
                    <v-card-title class="d-flex justify-center align-center">Totale sospeso</v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">{{localPaymentCredit.amount}} €</v-card-subtitle>
                  </div>
                </v-card>

                <v-btn
                  v-if="selectedServiceOrItem.length > 0"
                  icon
                  class="ml-4"
                  @click="singleOrMultiCredit(selectedServiceOrItem)"
                >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>

              </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name + '_else_if'"
                v-else-if="step.name == 'summary'"
              >
                <div
                  style="height: 70vh; width: 100%"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <PaymentSummary
                    ref="paymentSummary"
                    :total="totalValue"
                    :provided-entities="providedServices"
                    :item-price="(billService) => billService.service.price"
                    :item-name="(billService) => billService.service.name"
                    :opened-cash-desk="openedCashDesk"
                    :paid-with-cash.sync="paidWithCash"
                    :paid-with-card.sync="paidWithCard"
                    :paid-with-tickets.sync="paidWithTickets"
                    :paid-with-check.sync="paidWithCheck"
                    hide-not-paid
                    :hide-paid-amounts="invoiceOrReceipt == 'invoice' "
                    :customer="localCustomer"
                    @confirm="goToStep(currentStep + 1)"
                    @go-back="goBackToStep(currentStep - 1)"
                    :lottery-code.sync="lotteryCode"
                    :use-lottery-code.sync="useLotteryCode"
                  ></PaymentSummary>
                </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name + '_else_if_2'"
                v-else-if="step.name == 'payment'"
              >
                <div
                  style="height: 70vh;"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="invoicePrinted"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{invoicePrintedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="transactionClosed"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{transactionClosedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="dataRegistered"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{dataRegisteredMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center mt-5">
                    <v-btn icon @click="closeDialog" :disabled="canCloseDialog"></v-btn>
                  </div>
                </div>
              </v-stepper-content>
              <v-snackbar
                v-model="messageError"
                timeout="-1"
                v-bind:key="step.name"
              >
                {{ messageErrorText }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="messageError = false"
                  >
                    Chiudi
                  </v-btn>
                </template>
              </v-snackbar>
            </template>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template v-else>
        Non puoi procedere con la cassa chiusa
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import Printer from '@/services/devices/rtPrinter'
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'
import StandardDialog from '@/components/common/StandardDialog'
import dateUtils from '@/mixins/common/dateUtils'
import FullScreenDialog from '@/components/common/FullScreenDialog'
import InvoiceDataForm from '@/components/homepage/sections/InvoiceDataForm.vue'
import paymentCreditService from '@/services/paymentCredits/paymentCredits.service.js'
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue';
import AnimatedCheck from '@/components/common/AnimatedCheck.vue';
import PaymentSummary from '@/components/homepage/sections/PaymentSummary.vue';
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import customerService from '@/services/customers/customers.service.js';
import billService from '@/services/bills/bills.service.js'


export default {
  name: "PaymentCreditPayDialog",
  components: {
    StandardDialog,
    FullScreenDialog,
    OperatorAvatar,
    NumericKeyboardInput,
    AnimatedCheck,
    PaymentSummary,
    InvoiceDataForm,
    ResponsiveCardSelector
  },
  mixins: [dateUtils],
  data: function() {
    return {
      localCustomer: undefined,
      invoiceData: undefined,
      invoiceFormValid: false,
      loadingCustomerUpdate: false,
      localPaymentCredit: undefined,
      localDialog: this.dialog,
      currentStep: 1,
      invoiceOrReceipt: undefined,
      totalValue: undefined,
      receiptScrollY: 0,
      openedCashDesk: undefined,
      canScrollReceipt: false,
      paidWithCash: 0,
      paidWithCard: 0,
      paidWithTickets: 0,
      paidWithCheck: 0,
      notPaid: 0,
      transactionClosed: false,
      dataRegistered: false,
      invoicePrinted: false,
      canCloseDialog: true,
      canChangeStep: true,
      serviceToPay: undefined,
      paySingleOrMultiCreditBill: undefined,
      selectedServiceOrItem: [],
      selectedServiceOrItemList: [],
      creditServices: [],
      creditItems: [],
      creditServicesTotal: undefined,
      creditItemsTotal: undefined,
      messageError: false,
      messageErrorText: undefined,
      ctrlGoBack: false,
      lotteryCode: undefined,
      useLotteryCode: undefined,
      taxForSaloon: undefined
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    goBack: {
      type: Boolean,
      default: false
    },
    pathName: {
      type: String,
    },
    filters: {
      type: Array
    },
    goBackTo: { },
    paymentCreditId: { },
    paymentCredit: { },
    customer: { }
  },
  mounted: async function() {
    if(!!this.paymentCredit && !!this.paymentCredit.amount) {
      this.localPaymentCredit = {...this.paymentCredit}
      this.totalValue = Number(this.localPaymentCredit.amount)
    } else if(!!this.paymentCreditId) {
      paymentCreditService.get({id: this.paymentCreditId}).then(({paymentCredit}) => {
        this.totalValue = Number(paymentCredit.amount)
        this.localPaymentCredit = paymentCredit
      })
    }

    if(!!this.customer && !!this.customer.id) {
      this.localCustomer = await customerService.get(this.customer.id)
    }

    this.getOpenedCashDesk()
  },
  methods: {
    closeDialog() {
      if(!this.ctrlGoBack){
        this.$emit('update:dialog', false)
        let goBack;
        if(!!this.goBack) {
          this.
          goBack = () => {
            this.$router.go(-1)
          }
        } else if(!!this.goBackTo) {
          let goBackTo = {...this.goBackTo, params: { filters: this.filters }}
          goBack = () => {
            this.$router.push(goBackTo)
          }
          this.ctrlGoBack = true
        }
        setTimeout(goBack.bind(this), 200);
      }
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk
      })
    },
    handleFullScreenDialogChange(newVal) {
      if(!newVal) {
        this.closeDialog()
      }
    },
    startFromScratch() {
      this.invoiceOrReceipt = undefined
      this.currentStep = 1
    },
    setInvoiceOrReceipt(type) {
      this.invoiceOrReceipt = type
    },
    goToStep(number) {
      const step = this.steps[number - 1]
      if(step.name == 'summary') {
        setTimeout(() => {
          this.$refs.paymentSummary[0].calculateScrollReceipt()
        }, 200)
      } else if(step.name == 'payment') {
        if(this.paySingleOrMultiCreditBill == true){
          setTimeout(() => {
            this.paySingleOrMultiCredit()
          }, 1000);
        } else {
          setTimeout(() => {
            this.pay()
          }, 1000);
        }
      } 
      this.currentStep = number
    },
    goBackToStep(number) {
      const step = this.steps[number - 1]
      if(step.name == 'invoiceOrReceipt') {
        this.invoiceOrReceipt = undefined
      }
      this.goToStep(number)
    },
    handleStepClicked(number) {
      if(this.currentStep == number || !this.canChangeStep) {
        return
      } else if(this.currentStep < number) {
        this.$delegates.snackbar("Confermare prima di avanzare")
      } else {
        this.goBackToStep(number)
      }
    },
    handleReceiptClick() {
      if(!this.localPaymentCredit.schedulePaymentsId && !!this.localPaymentCredit.bills.length) {
        this.creditServices = this.localPaymentCredit.bills[0].billServices
        this.creditServices = this.creditServices.filter((service) => {
          return service.status != 'paid'
        })
        
        this.creditItems = this.localPaymentCredit.bills[0].billItems
        this.creditServicesTotal = 0
        this.creditServices.map((cs) => {
          this.creditServicesTotal += Number((Number(cs.price) * Number(cs.quantity)).toFixed(2))
        })
        this.creditServicesTotal = Number(this.creditServicesTotal.toFixed(2))
        this.creditItemsTotal = 0
        this.creditItems.map((ci) => {
          this.creditItemsTotal += Number(ci.price)
        })
        this.creditItemsTotal = Number(this.creditItemsTotal.toFixed(2))
      }

      this.totalValue = Number(this.localPaymentCredit.amount)
      this.setInvoiceOrReceipt('receipt');
      this.paySingleOrMultiCreditBill = false
      this.$nextTick(() => {
        this.goToStep(2)
      })
    },
    handleServiceReceiptClick() {
      this.setInvoiceOrReceipt('receipt');
      this.$nextTick(() => {
        this.goToStep(2)
      })
    },
    updateCustomerDataInvoice() {
      if(!!this.customer && !!this.invoiceData) {
        let dataInvoice = {...this.invoiceData}
        dataInvoice.lastname = dataInvoice.companyNameOrLastname
        delete dataInvoice.companyNameOrLastname

        this.loadingCustomerUpdate = true
        customerService.update({
          id: this.customer.id,
          dataInvoice: dataInvoice
        }).then((results) => {
          this.loadingCustomerUpdate = false
          this.$delegates.snackbar('Salvataggio avvenuto correttamente')
        })
      }
    },
    async pay() {
      try {
        this.transactionClosed = false
        this.dataRegistered = false
        this.invoicePrinted = false
        this.canCloseDialog = false
        this.canChangeStep = false

        if(Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue)) {
          this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0)) {
          this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)) {
          this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCheck) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)) {
          this.paidWithCheck = Number(this.totalValue)
        }

        let paymentTypes = []
        if(!!this.paidWithCash) {
          paymentTypes.push({
            amount: this.paidWithCash,
            type: 'cash'
          })
        }

        if(!!this.paidWithCard) {
          paymentTypes.push({
            amount: this.paidWithCard,
            type: 'card'
          })
        }

        if(!!this.paidWithTickets) {
          paymentTypes.push({
            amount: this.paidWithTickets,
            type: 'ticket'
          })
        }

        if(!!this.paidWithCheck) {
          paymentTypes.push({
            amount: this.paidWithCheck,
            type: 'cheque'
          })
        }

        if(paymentTypes.length == 0) {
          paymentTypes.push({
            amount: 0,
            type: 'cash'
          })
        }

        try {
          let printerStatus = await Printer.getStatus()
        } catch (exception) {
          console.log(exception)

          this.$delegates.snackbar('Errore durante la connessione con la stampante')
          this.canCloseDialog = true
          return
        }

        let rtResponse
        
        if(this.invoiceOrReceipt == 'receipt') {
          try {
            if(!this.localPaymentCredit.schedulePaymentsId) {
              if (!!this.creditServices && this.creditServices.length > 0) {
                rtResponse = await Printer.printFiscalReceipt({
                  paymentTypes: paymentTypes,
                  amount: this.creditServicesTotal,
                  lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
                  items: this.creditServices.map(cs => ({
                    name: cs.service.name.replace("&", "e"),
                    price: cs.price,
                    quantity: cs.quantity,
                    tax: cs.tax === undefined || cs.tax === null ? '22%' : cs.tax + '22%'
                  })),
                  additionalRows: !!this.localPaymentCredit && !!this.localPaymentCredit.documentNumber ? [
                    "Riferimento al documento commerciale " + this.localPaymentCredit.documentNumber
                  ] : []
                }, "debiti receipt")
              }
              else if (this.localPaymentCredit.type == 'customerCards_transaction'){
                rtResponse = await Printer.printFiscalReceipt({
                  paymentTypes: paymentTypes,
                  amount: this.creditServicesTotal,
                  lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
                  items: [{
                    name: 'Ricarica tessera',
                    price: this.localPaymentCredit.amount,
                    quantity: 1,
                    tax: this.taxForSaloon === undefined || this.taxForSaloon === null ? '22%' : this.taxForSaloon + '%'
                  }],
                }, "customerCards_transaction receipt")
              }
            } else {
              rtResponse = await Printer.printFiscalReceipt({
                  paymentTypes: paymentTypes,
                  amount: this.localPaymentCredit.amount,
                  lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
                  items: [
                    {
                      name: `Pagamento rata`,
                      description: ``,
                      price: this.localPaymentCredit.amount,
                      quantity: 1,
                      tax: this.taxForSaloon === undefined || this.taxForSaloon === null ? '22%' : this.taxForSaloon + '%'
                    }
                  ],
                  additionalRows: []
              }, "pagamento rata receipt")
            }
            
            if (!!this.creditItems && this.creditItems.length > 0) {
              rtResponse = await Printer.printSummary({
                amount: Number(this.creditItemsTotal),
                items: this.creditItems.map(ci => ({
                  name: ci.item.description.replace("&", "e"),
                  price: Number(ci.price),
                  quantity: Number(ci.quantity),
                  tax: ci.tax === undefined || ci.tax === null ? '22%' : ci.tax + '22%'
                })),
                additionalRows: !!this.localPaymentCredit && !!this.localPaymentCredit.documentNumber ? [
                    "Importo Pagato: " + this.creditItemsTotal,
                    "Riferimento al documento commerciale " + this.localPaymentCredit.documentNumber
                ] : [
                    "Importo Pagato: " + this.creditItemsTotal
                ]
              }, false, "debiti summary")
            }
          } catch(exception) {
            console.log(exception)

            if (String(exception).includes("URL") || String(exception).includes("protocol")) {
              this.$delegates.snackbar('Stampante non collegata / Indirizzo stampante errato', 0)
              this.canCloseDialog = true
              return
            }

            // if something goes wrong manually set the success to false
            rtResponse = {
              attributes: {
                success: 'false'
              }
            }
          }
        }

        if(!!rtResponse && !!rtResponse.attributes && rtResponse.attributes.success == 'false') {
          this.$delegates.snackbar('Errore durante la stampa dello scontrino')
          this.canCloseDialog = true
          return 
        }

        let transaction
        this.invoicePrinted = true
        if(this.invoiceOrReceipt == 'receipt') {
          try {
            transaction = await paymentCreditService.pay(this.localPaymentCredit, {
              cashDesk: this.openedCashDesk,
              amount: this.totalValue,
              documentType: 'receipt',
              cashPaid: this.paidWithCash,
              cardPaid: this.paidWithCard,
              checkPaid: this.paidWithCheck,
              otherPaid: this.paidWithTickets,
              cashDeskLog: this.openedCashDesk.cashDeskLog.id,    
              customer: this.localCustomer      
            })
          } catch(err) {
            console.log(err)
            this.$delegates.snackbar('Errore durante il pagamento')
            this.canCloseDialog = true
            return
          }
        }

        if (!!transaction && !!rtResponse) {
          let documentNumberFixed = undefined
          if (!!rtResponse && !!rtResponse.addInfo) {
            rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
            rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
            while (rtResponse.addInfo.zRepNumber.length < 4) {
              rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
            }
            while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
              rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
            }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined'
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
              documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber

            billService.updateDocumentNumber(transaction.paymentTransactionId, documentNumberFixed)
          }
        }


        this.canCloseDialog = true
        this.transactionClosed = true
        this.dataRegistered = true
        setTimeout(this.closeDialog,2000)
      } catch(err) {
        // KEEP LOG
        console.log(err)

        this.$delegates.snackbar("Si è verificato un errore generico");
      }
    },
    singleOrMultiCredit(payServiceItemList) {
      //let totalServices = this.paymentCredit.bills[0].billServices.length + this.paymentCredit.bills[0].billItems.length
      if(payServiceItemList.length != this.billServiceItemList.length) {
        let totalSingleOrMulti = 0
        for(let i = 0; i < payServiceItemList.length; i++){
          totalSingleOrMulti = (Number(payServiceItemList[i].price) * Number(payServiceItemList[i].quantity)) + Number(totalSingleOrMulti)
        }
        this.paySingleOrMultiCreditBill = true
        this.totalValue = Number(totalSingleOrMulti.toFixed(2))
        this.setInvoiceOrReceipt('receipt');
        this.$nextTick(() => {
          this.goToStep(2)
        })
      } else {
        this.handleReceiptClick()
      }
      
    },

    async paySingleOrMultiCredit() {
      try {
        this.transactionClosed = false
        this.dataRegistered = false
        this.invoicePrinted = false
        this.canCloseDialog = false
        this.canChangeStep = false

        if(Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue)) {
          this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0)) {
          this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)) {
          this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCheck) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)) {
          this.paidWithCheck = Number(this.totalValue)
        }

        let paymentTypes = []
        if(!!this.paidWithCash) {
          paymentTypes.push({
            amount: this.paidWithCash,
            type: 'cash'
          })
        }

        if(!!this.paidWithCard) {
          paymentTypes.push({
            amount: this.paidWithCard,
            type: 'card'
          })
        }

        if(!!this.paidWithTickets) {
          paymentTypes.push({
            amount: this.paidWithTickets,
            type: 'ticket'
          })
        }

        if(!!this.paidWithCheck) {
          paymentTypes.push({
            amount: this.paidWithCheck,
            type: 'cheque'
          })
        }

        if(paymentTypes.length == 0) {
          paymentTypes.push({
            amount: 0,
            type: 'cash'
          })
        }

        try {
          let printerStatus = await Printer.getStatus()
        } catch (exception) {
          console.log(exception)

          this.$delegates.snackbar('Errore durante la connessione con la stampante')
          this.canCloseDialog = true
          return
        }

        let rtResponse
        if(this.invoiceOrReceipt == 'receipt') {
          try {
            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              items: this.providedServices.map(ps => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
              })),
              additionalRows: !!this.localPaymentCredit && !!this.localPaymentCredit.documentNumber ? [
                "Riferimento al documento commerciale " + this.localPaymentCredit.documentNumber
              ] : []
            }, "debiti receipt")
          } catch(exception) {
            console.log(exception)

            if (String(exception).includes("URL") || String(exception).includes("protocol")) {
              this.$delegates.snackbar('Stampante non collegata / Indirizzo stampante errato', 0)
              this.canCloseDialog = true
              return
            }

            // if something goes wrong manually set the success to false
            rtResponse = {
              attributes: {
                success: 'false'
              }
            }
          }
        }

        if(rtResponse.attributes.success == 'false') {
          this.$delegates.snackbar('Errore durante la stampa dello scontrino')
          this.canCloseDialog = true
          return 
        }

        let transaction
        this.invoicePrinted = true
        if(this.invoiceOrReceipt == 'receipt') {
          try {
            transaction = await paymentCreditService.paySingleOrMultiCredit(this.paymentCredit, {
              cashDesk: this.openedCashDesk,
              amount: this.totalValue,
              documentType: 'receipt',
              cashPaid: this.paidWithCash,
              cardPaid: this.paidWithCard,
              checkPaid: this.paidWithCheck,
              otherPaid: this.paidWithTickets,
              selectedServiceOrItem: this.selectedServiceOrItem,
              cashDeskLog: this.openedCashDesk.cashDeskLog.id,
              customer: this.localCustomer
            })
          } catch(err) {
            console.log(err)
            this.$delegates.snackbar('Errore durante il pagamento')
            this.canCloseDialog = true
            return
          }
        }

        if (!!transaction && !!rtResponse) {
          let documentNumberFixed = undefined
          if (!!rtResponse && !!rtResponse.addInfo) {
            rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
            rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
            while (rtResponse.addInfo.zRepNumber.length < 4) {
              rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
            }
            while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
              rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
            }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined'
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
              documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber

            billService.updateDocumentNumber(transaction.paymentTransactionId, documentNumberFixed)
          }
        }

        this.canCloseDialog = true
        this.transactionClosed = true
        this.dataRegistered = true
        setTimeout(this.closeDialog,2000)
      } catch(err) {
        if(String(err).includes("URL") || String(err).includes("protocol")) {
          this.messageErrorText = 'Stampante non collegata / Indirizzo stampante errato'
          this.messageError = true
        } else {
          this.messageErrorText = err
          this.messageError = true
        }
      }
    },
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal
      if(newVal) {
        this.startFromScratch()
      }
    }
  },
  computed: {
    billServiceItemList() {
      if(this.paymentCredit.bills.length != 0) {
        let serviceItemToPay = [...this.paymentCredit.bills[0].billServices.filter((bs)=> bs.status !== 'paid'),...this.paymentCredit.bills[0].billItems.filter((bs)=> bs.status !== 'paid')]
        let currentTotalCredit = 0
        for(let i = 0; i < serviceItemToPay.length; i++) {
          currentTotalCredit = currentTotalCredit + (Number(serviceItemToPay[i].price) * Number(serviceItemToPay[i].quantity))
        }
        this.localPaymentCredit.amount = currentTotalCredit.toFixed(2)
        return serviceItemToPay
      } else {
        return []
      }
    },
    steps() {
      let steps = []

      if(!this.invoiceOrReceipt) {
        steps.push({
          title: 'Scontrino',
          name: "invoiceOrReceipt"
        })
      } else if(this.invoiceOrReceipt == 'receipt') {
        steps.push({
          title: 'Scontrino',
          name: "invoiceOrReceipt"
        })
      }

      steps.push({
        title: 'Riepilogo',
        name: 'summary'
      })

      steps.push({
        title: 'Pagamento',
        name: 'payment'
      })

      return steps
    },
    providedServices() {
      if(!this.localPaymentCredit) {
        return []
      } else {
        return [
          {
            id: 1,
            service: {
              name: 'Saldo credito ' + this.dateToString('dd/mm/yyyy', new Date(this.localPaymentCredit.openedAt)),
              price: this.totalValue,
            },
            ghost: false,
            quantity: 1
          }
        ]
      }
    },
    cashDeskOpened() {
      return !!this.openedCashDesk
    },
    transactionClosedMessage() {
      return this.transactionClosed ? 'Transazione eseguita correttamente' : 'Transazione in corso ...'
    },
    dataRegisteredMessage() {
      return this.dataRegistered ? 'Dati registrati correttamente' : 'Registrazione dei dati ...'
    },
    invoicePrintedMessage() {
      return this.invoicePrinted ? 'Scontrino stampato correttamente' : 'Stampa dello scontrino ...'
    }
  },
  settings: {
    taxForSaloon: {
      bind: 'taxForSaloon'
    }
  },
}
</script>

<style>

</style>